<template>
  <v-container>
    <v-row v-if="input.range">
      <v-col cols="6" class="pa-0">
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
              <v-text-field
                v-model="dateFrom"
                dense
                :label="input.label"
                :required="input.required"
                hint="YYYY/MM/DD"
                persistent-hint
                prepend-icon="mdi-calendar-question"
                readonly
                outlined
                v-bind="attrs"
                class="sm-2"
                clearable
                @input="resetRangeCalendarFrom"
              >
              </v-text-field>
            </div>
          </template>
          <v-date-picker
            :ref="`calendar-${index}`"
            v-if="input.range"
            v-model="dateFrom"
            @input="menu1 = false"
            no-title
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="1" class="pa-0 d-flex justify-center">
        <span style="font-size: x-large">~</span>
      </v-col>
      <v-col cols="5" class="pa-0">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
              <v-text-field
                v-model="dateTo"
                dense
                :label="input.label"
                :required="input.required"
                hint="YYYY/MM/DD"
                persistent-hint
                readonly
                outlined
                v-bind="attrs"
                class="sm-2"
                clearable
                @input="resetRangeCalendarTo"
              ></v-text-field>
            </div>
          </template>
          <v-date-picker :ref="`calendar-${index}`" :min="dateFrom" @input="menu2 = false" v-model="dateTo" no-title>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row v-else-if="!input.range || undefined">
      <v-col cols="12" class="pa-0">
        <v-menu
          v-model="menu3"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
              <v-text-field
                v-model="dateText"
                dense
                :label="input.label"
                :required="input.required"
                hint="YYYY/MM/DD"
                persistent-hint
                prepend-icon="mdi-calendar-question"
                outlined
                v-bind="attrs"
                v-on="on"
                class="sm-2 min-width"
                clearable
                @input="resetCalendarFrom"
              ></v-text-field>
            </div>
          </template>
          <v-date-picker :ref="`calendar-${index}`" @input="menu3 = false" v-model="date" no-title> </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import moment from 'moment';
export default {
  props: ['input', 'reactiveVarKey', 'index', 'initDate'],
  data() {
    return {
      menu1: false,
      menu2: false,
      menu3: false,
      dateFrom: null,
      dateTo: null,
      date: null,
    };
  },
  mounted() {
    if (this.initDate && !this.input.range) {
      this.date = this.initDate ? moment(this.initDate).format('YYYY-MM-DD') : null;
    } else if (this.initDate && this.input.range) {
      this.dateFrom = this.initDate[0] ? moment(this.initDate[0]).format('YYYY-MM-DD') : null;
      this.dateTo = this.initDate[1] ? moment(this.initDate[1]).format('YYYY-MM-DD') : null;
    }
  },
  computed: {
    dateText() {
      return this.date ? moment(this.date).format('YYYY/MM/DD') : null;
    },
    labels() {
      return this.$store.getters['defined/labels'];
    },
  },
  methods: {
    resetRangeCalendarFrom(val) {
      this.dateFrom = null;
      this.$emit('date-range', [val, this.dateTo], this.reactiveVarKey);
    },
    resetRangeCalendarTo() {
      this.dateTo = null;
      this.$emit('date-range', [this.dateFrom, null], this.reactiveVarKey);
    },
    resetCalendarFrom() {
      this.date = null;
      this.$emit('date-range', this.date, this.reactiveVarKey);
    },
  },
  watch: {
    dateFrom(val) {
      this.$emit('date-range', [val, this.dateTo], this.reactiveVarKey);
      if (val) {
        this.menu1 = false;
      }
    },
    dateTo(val) {
      this.$emit('date-range', [this.dateFrom, val], this.reactiveVarKey);
      if (val) {
        this.menu2 = false;
      }
    },
    dateText(val) {
      this.$emit('date-range', val, this.reactiveVarKey);
      if (val) {
        this.menu3 = false;
      }
    },
  },
};
</script>

<style scoped>
.min-width {
  min-width: 280px;
}
</style>
